<template>
  <div
    class="dialogue-component"
    :class="[
      this.$store.state.mobileView ? 'mobile' : 'desktop',
      this.$store.state.mode !== 'inperson'
        ? this.$store.state.mode === 'textmessage'
          ? 'phone'
          : 'post'
        : 'outside',
    ]"
  >
    <PhoneConversation v-if="this.$store.state.mode === 'textmessage'" />
    <InPersonConversation v-if="this.$store.state.mode === 'inperson'" />
    <PostIt v-if="this.$store.state.mode === 'postit'" />
  </div>
</template>

<script>
import InPersonConversation from "@/components/InPersonConversation.vue";
import PhoneConversation from "@/components/PhoneConversation.vue";
import PostIt from "@/components/PostIt.vue";

export default {
  name: "Dialogue",
  components: {
    InPersonConversation,
    PhoneConversation,
    PostIt,
  },
  mounted() {
    window.dispatchEvent(new Event("resize"));
    this.$store.commit("setProgress", this.$store.state.percentageComplete);
  },
  computed: {
    mode: function () {
      return this.$store.state.mode;
    },
  },
};
</script>

<style scoped>
.dialogue-component {
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
</style>
